import React, { useState } from 'react';
import Slider from 'react-slick';
import { GatsbyImage, getImage } from 'gatsby-plugin-image';
import PropTypes from 'prop-types';
import Title from '../../molecules/title/title';
import GenericModal from '../../molecules/generic-modal/GenericModal';
import { PrevArrow, NextArrow } from '../../molecules/arrows/arrows';
import { Wrapper } from '../../utils/components';
import { getCommonOrganismData } from '../../utils/functions';
import useWithMobileResizeListener from '../../utils/hooks/useWithMobileResizeListener';
import withPreview from '../../utils/with-preview';
import classNames from 'classnames';
import AnchorHooking from '../../molecules/anchor-hooking/AnchorHooking';

import './Photogallery.scss';

const Photogallery = ({ data, considerMenuSpace }) => {
  const isMobile = useWithMobileResizeListener(768, 500);
  const [isModalOpen, setModalOpen] = useState(false);
  const [slideOpen, setSlideOpen] = useState(null);
  const [childSliderCurrentSlide, setChildSliderCurrentSlide] = useState(null);

  const {
    anchorLabel,
    backgroundColor,
    htmlTagWrapper,
    marginTop,
    title,
    titleHeadingTag,
    titleIconId,
    titleIconThickness,
  } = getCommonOrganismData(data);

  const disableSliderUnder = data?.disableSliderUnder?.value || false;
  const disableSliderOver = data?.disableSliderOver?.value || false;
  const hideDots = data?.hideDots?.value || false;
  const showNavigationArrows = data?.showArrows?.value || false;
  const images = data?.photogalleryImages || [];

  const imagesToRender = images.map((image, index) => {
    return (
      <button
        onClick={() => {
          setModalOpen(true);
          setSlideOpen(index);
          setChildSliderCurrentSlide(index);
        }}
        key={`photogallery__image-${index}`}
      >
        <GatsbyImage
          image={getImage(image?.previewImages?.gatsbyImageData)}
          height='100%'
          className='photogallery__image'
          alt={image?.value?.title || ''}
        />
      </button>
    );
  });

  const innerSliderImages = images.map((image, index) => {
    return (
      <GatsbyImage
        objectFit='contain'
        image={getImage(image?.previewImages?.gatsbyImageData)}
        key={`modal__image-${index}`}
        className='photogallery-modal__item'
        imgClassName='photogallery-modal__item-img'
        alt={image?.value?.title || ''}
      />
    );
  });

  const imagesSlick = imagesToRender.map((image, index) => {
    // slick does not space elements between them, adding a class does the trick
    return (
      <div key={`photogallery__item-${index}`} className='photogallery__item'>
        {image}
      </div>
    );
  });

  const renderGallery = () => {
    const sliderSettings = {
      infinite: false, // If is set to true, if elements < sideToShow it generetes clone tile
      dots: !hideDots,
      speed: 500,
      arrows: showNavigationArrows,
      slidesToShow: 3,
      slidesToScroll: 3,
      slide: '',
      prevArrow: showNavigationArrows && <PrevArrow />,
      nextArrow: showNavigationArrows && <NextArrow />,
      responsive: [
        {
          breakpoint: 768,
          settings: {
            slidesToShow: 1,
            slidesToScroll: 1,
          },
        },
      ],
      appendDots: (dots) => (
        <div>
          <ul style={{ margin: '0px' }}> {dots} </ul>
        </div>
      ),
      customPaging: () => <div className='slick-dots__custom-dot'></div>,
    };
    const explodedGallery = (
      <div className='photogallery__exploded'>{imagesToRender}</div>
    );

    const renderGalleryMobile = () => {
      return disableSliderUnder || images.length <= 1 ? (
        explodedGallery
      ) : (
        <Slider {...sliderSettings}>{imagesSlick}</Slider>
      );
    };
    const renderGalleryDesktop = () => {
      return disableSliderOver || images.length <= 3 ? (
        explodedGallery
      ) : (
        <Slider {...sliderSettings}>{imagesSlick}</Slider>
      );
    };
    return isMobile ? renderGalleryMobile() : renderGalleryDesktop();
  };

  const closeModal = () => {
    setModalOpen(false);
  };

  return (
    <Wrapper
      tag={htmlTagWrapper}
      data-module-name='photogallery'
      data-module-code='C-60'
      style={{ '--bg': `var(--${backgroundColor})` }}
      className={classNames(
        `photogallery wrapper wrapper--overflow ${marginTop}`,
        {
          'photogallery--colored-background': backgroundColor !== 'transparent',
        }
      )}
    >
      <AnchorHooking id={anchorLabel} belowMenu={considerMenuSpace} />
      {(title || titleIconId) && (
        <Title
          tag={titleHeadingTag}
          titleIconId={titleIconId}
          titleIconThickness={titleIconThickness}
        >
          {title}
        </Title>
      )}
      <div className='photogallery__items'>{renderGallery()}</div>
      {isModalOpen && (
        <GenericModal
          className='photogallery__modal'
          isOpen={isModalOpen}
          onClose={closeModal}
          title={
            images[childSliderCurrentSlide]?.content?.imageDescription?.value ||
            ''
          }
        >
          <Slider
            adaptiveHeight
            arrows
            beforeChange={(oldIndex, newIndex) => {
              // workaround as afterChange does not fire when adaptiveHeight is true (react-slick bug)
              setChildSliderCurrentSlide(newIndex);
            }}
            className='photogallery__modal-slider'
            dots={false}
            infinite // must set infinite as otherwise nextArrows opens the second slide at first click (react-slick bug)
            initialSlide={slideOpen}
            nextArrow={<NextArrow />}
            prevArrow={<PrevArrow />}
            slidesToScroll={1}
            slidesToShow={1}
            speed={500}
          >
            {innerSliderImages}
          </Slider>
        </GenericModal>
      )}
    </Wrapper>
  );
};

Photogallery.propTypes = {
  data: PropTypes.object,
};

export default withPreview(Photogallery);
